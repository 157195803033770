import Userimage from '../../assets/images/aliakbar.jpg';
import AlphaLogo from './../../assets/images/alpha.png';
import React, {useState} from 'react';

const Header = (props) => {


    return (
        <div className={`header-container ${props.starterChat ? 'header-container-chat' : ''}`}
             style={{backgroundColor: props.starterChat && '#131314'}}>

            <img src={AlphaLogo}  className='header-logo'/>
            <div className="header-user-container">
            <img src={Userimage} className='header-user-avatar' />

                <span className='header-user-text'  >Aliakbar Hamzeh</span>

                {/*<img src={DownArrow} className='header-user-collapse' />*/}

            </div>

        </div>
    )
}
export default Header;