import React, {useState} from "react";
import './../assets/css/login.css'
import LoginLogo from './../assets/images/login.svg'
import axios from 'axios'
import {useNavigate} from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState(0)
    const navigate = useNavigate();

    const login_with_google = () => {
        axios.get('http://localhost:8000/api/v1/google/')
            .then(response => {
                console.log('Response:', response.data.data.auth_url);
                window.location.href = response.data.data.auth_url;

            })
            .catch(error => {
                console.error('Error:', error);
            });

    }
    return (<>
        <div className={"login-body"}>
            <div className={"login-container"}>
                <img src={LoginLogo} className={"login-logo"}/>
                <div className={"login-data-container"}>
                    <div className={"login-input-container"}>
                        <input className={"login-email-input"} placeholder={"Your email"}/>
                        <button className={"login-email-submit"}>
                            <span className={'login-email-submit-span'}>
                                Continue with email
                            </span>
                        </button>
                    </div>

                    <span className={"login-or-span"}>
                        OR
                    </span>
                    <button className={"login-email-submit"} onClick={(e) => {
                        login_with_google()
                    }}>
                        <span className={'login-email-submit-span'}>
                            Continue with Google
                        </span>
                    </button>
                    <span className={"login-terms-text"}>
                        By continuing, you agree to Alpha Consumer Terms and
                        Acceptable Use Policy, and acknowledge their Privacy Policy.
                    </span>
                </div>
                <span className={"login-warning"}>
                    Alpha is currently in the initial testing stage, and as a result, it is possible that the answers received may not be accurate.
                </span>
            </div>
        </div>
    </>)
}
export default Login;