import VideoCall from './../../assets/images/video-call.svg';
import Call from './../../assets/images/call.svg';

const ChatHeader = () => {

    return (
        <div className="chat-header-container">
            <a className="chat-header-title">Alpha</a>
            <div style={{ }}>
                <img src={VideoCall} className='chat-header-icon' />
                <img src={Call} className='chat-header-icon' />
            </div>
        </div>
    )
}
export default ChatHeader;