import Message from './message';

const ChatList = (props) => {

    return (
        <div className='chat-chat-list' style={{backgroundColor:props.mainChat && '#131314'}}>
            {
                props.messages.map((item,index) =>(
                    <Message isBot={item.isBot} key={index} text={item.data}/>

                ))
            }
        </div>
    )
}
export default ChatList;