import './../../assets/css/flowchart.css'
import 'reactflow/dist/style.css';

import ReactFlow, {addEdge, Background, MiniMap, useEdgesState, useNodesState, useReactFlow} from 'reactflow';
import React, {useCallback, useState} from "react";
import ModelNode from './ModelDataNode';
import Toolbar from "../general/Toolbar";

const initBgColor = '#1A192B';
const connectionLineStyle = {stroke: '#fff'};
const nodeTypes = {
    selectorNode: ModelNode,
};
const getModelRelations = (jsonData) => {
    const relations = {};

    jsonData.data.models.forEach(model => {
        relations[model.name] = [];

        model.fields.forEach(field => {
            if (['ForeignKey', 'OneToOneField', 'ManyToManyField'].includes(field.type)) {
                relations[model.name].push({
                    field: field.name, related_model: field.to
                });
            }
        });
    });

    return relations;
};
const Flowchart = (props) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [bgColor, setBgColor] = useState(initBgColor);
    const {setViewport, zoomIn, zoomOut} = useReactFlow();
    const onConnect = useCallback((params) => {
        setEdges((eds) => addEdge({...params, animated: true, style: {stroke: '#fff'}}, eds))
    }, []);
    const [tab, setTab] = useState('Start')

    const connection = getModelRelations(props.configJson);
    const openModal = (title) => {
        props.setAddItemModal(prevState => ({
            ...prevState, show: true, title: title, model_name: title
        }));
    }

    const onInit = (reactFlowInstance) => {
        const clickOnAddField = (title, id) => {
            openModal(title)
        }
        const newNodes = props.configJson.data.models.map((item) => ({
            id: item.name.toString(),
            type: 'selectorNode',
            position: {x: 300, y: 50},
            data: {title: `${item.name}`, fields: item.fields, clickOnAddField: clickOnAddField},
        }));

        setNodes((prevNodes) => [...prevNodes, ...newNodes]);

        let id = 0;
        let newEdges = [];
        Object.entries(connection).forEach(([key, value]) => {
            if (value.length !== 0) {
                value.forEach(item => {
                    id++;
                    newEdges.push({
                        id: `${id}`,
                        source: key,
                        target: item.related_model,
                        sourceHandle: `${key}_${item.field}`,
                        animated: true,
                        style: {stroke: '#fff'},
                    });
                });
            }
        });
        setEdges((prevEdges) => [...prevEdges, ...newEdges]);
    };
    const ZoomIn_action = () => {
        zoomIn({duration: 800})
    }
    const ZoomOut_action = () => {
        zoomOut({duration: 800})
    }
    const Magic_action = () => {
        console.log("Magic_action")
    }
    const Import_action = () => {
        console.log("Import_action")
    }
    const Export_action = () => {
        console.log("Export_action")
    }
    const Code_action = () => {
        console.log("Code_action")
    }
    const handleTransform = useCallback(() => {
        setViewport({x: 0, y: 0, zoom: 1}, {duration: 800});
    }, [setViewport]);
    const ViewPort_action = () => {
        handleTransform()
    }
    return (<div className={"toolbar-and-flow-container"}>
            <Toolbar
                show={!props.starterChat}
                tab={tab}
                setTab={setTab}
                setShowLeftSideBar={props.setShowLeftSideBar}
                ZoomIn={ZoomIn_action}
                ZoomOut={ZoomOut_action}
                Magic={Magic_action}
                Import={Import_action}
                Export={Export_action}
                Code={Code_action}
                ViewPort={ViewPort_action}

            />
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                style={{background: "#272727", display: !props.starterChat ? 'block' : 'none',}}
                nodeTypes={nodeTypes}
                connectionLineStyle={connectionLineStyle}
                fitView
                attributionPosition="bottom-left"
                onInit={onInit}
            >
                <Background/>
                <MiniMap
                    nodeStrokeColor={(n) => {
                        if (n.type === 'input') return '#0041d0';
                        if (n.type === 'selectorNode') return bgColor;
                        if (n.type === 'output') return '#ff0072';
                    }}
                    nodeColor={(n) => {
                        if (n.type === 'selectorNode') return bgColor;
                        return '#fff';
                    }}
                />
            </ReactFlow>
        </div>

    )
}
export default Flowchart;