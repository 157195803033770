import Userimage from '../../assets/images/aliakbar.jpg';
import AlphaChatImage from './../../assets/images/alpha-chat.svg';

const Message = (props) => {

    return (
        <div className={`chat-message-container ${props.isBot ? 'bot' : 'user'}`}>
            <div className={`chat-message-container `}>
                <img src={props.isBot ?Userimage:AlphaChatImage} className='chat-message-avatar' />

            </div>
            <div className='chat-message-container-text'>
                <div className={`chat-message-container-text-metadata  ${props.isBot ? 'bot' : 'user'}`}>
                    <span className='chat-message-sender-name'>{props.isBot ?"Aliakbar":"Alpha junior"}</span>
                </div>
                <div className={`chat-message-area ${props.isBot ? 'bot' : 'user'}`}>
                    <span className={`chat-message-area-text ${props.isBot ? 'bot' : 'user'}`}>
                        {props.text}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default Message;