import React, {memo, useState} from 'react';
import {Handle, Position} from 'reactflow';
import Arrow from './../../assets/images/arrow-2.svg';
import DownArrow from './../../assets/images/down-arrow.svg';

const typeMapping = {
    'CharField': 'String',
    'EmailField': 'Email',
    'IntegerField': 'Integer Number',
    'ManyToManyField': 'Relation',
    'TextField': 'Text',
    'DecimalField': 'Float Number',
    'DateField': 'Date',
    'ForeignKey': 'Relation',
    'OneToOneField': 'Relation',

}
export default memo(({data, isConnectable}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const toggleExpand = () => {
        setIsExpanded(prevState => !prevState);
    };

    return (
        <div>
            <div className='Custom-Node-container'>
                <img src={Arrow} className='Custom-Node-Icon'/>

                <span className="Custom-Node-Title">
                    {data.title}
                </span>
                <img src={DownArrow} className='Custom-Node-collapse' onClick={toggleExpand}/>

            </div>
            {isExpanded &&
                data.fields.map((item, index) => (

                    <React.Fragment key={`${data.title}_${item.name}`}>
                        <div className='Custom-Node-field-container '>
                            <span className='Custom-Node-field-title'>{item.name}</span>
                            <span className='Custom-Node-field-value'>{typeMapping[item.type]}</span>
                            {
                                // id={`${data.title}_${item.name}`}

                                typeMapping[item.type] === "Relation" &&
                                <Handle
                                    type="source"
                                    position={Position.Right}
                                    id={`${data.title}_${item.name}`}
                                    style={{top: parseInt((index * 40) + 65), background: '#555'}}
                                    isConnectable={isConnectable}
                                />
                            }
                        </div>
                        {index !== data.fields.length - 1 ? <div className='Custom-Node-divider'/> :
                            <div className='Custom-Node-divider-last-item'/>}
                    </React.Fragment>
                ))
            }
            <Handle
                type="target"
                position={Position.Left}
                id={`${data.title}`}

            />
            {isExpanded &&
                <div className="Custom-Node-add-new-item-container">
                    <span onClick={() => data.clickOnAddField(data.title, 1)}>Add</span>
                </div>

            }
        </div>
    );
});
