import React, {useEffect, useState} from 'react';

import '../assets/css/general.css';
import Chat from '../components/chat';
import Header from '../components/general/Header';
import LeftSidebar from '../components/general/left_sidebar';
import "react-responsive-modal/styles.css";
import AddFieldModal from "../components/flowchart/AddFieldModal";
import Flowchart from "../components/flowchart";
import {ReactFlowProvider} from "reactflow";
import StartChat from "../components/start_chat";
import {useNavigate} from "react-router-dom";


const App = () => {

    const [configJson, setConfigJson] = useState(
        {
            "configs": {
                "choices": {
                    "Draft": "warning",
                    "Pending": "warning",
                    "Confirmed": "success",
                    "Approved": "success",
                    "Shipped": "info",
                    "Delivered": "primary"
                }
            },
            "data": {
                "project_name": "parental_control_app",
                "name": "parental_control",
                "models": [
                    {
                        "name": "Parent",
                        "slug": "parent",
                        "fields": [
                            {
                                "name": "username",
                                "type": "CharField",
                                "max_length": 200,
                                "unique": true,
                                "null": false,
                                "blank": false,
                                "verbose_name": "Username"
                            },
                            {
                                "name": "email",
                                "type": "EmailField",
                                "unique": true,
                                "null": false,
                                "blank": false,
                                "verbose_name": "Email"
                            },
                            {
                                "name": "password",
                                "type": "CharField",
                                "max_length": 200,
                                "null": false,
                                "blank": false,
                                "verbose_name": "Password"
                            }
                        ]
                    },
                    {
                        "name": "ChildDevice",
                        "slug": "child_device",
                        "fields": [
                            {
                                "name": "device_name",
                                "type": "CharField",
                                "max_length": 200,
                                "null": false,
                                "blank": false,
                                "verbose_name": "Device Name"
                            },
                            {
                                "name": "parent",
                                "type": "ForeignKey",
                                "related_name": "parent_devices",
                                "to": "Parent"
                            }
                        ]
                    },
                    {
                        "name": "Application",
                        "slug": "application",
                        "fields": [
                            {
                                "name": "name",
                                "type": "CharField",
                                "max_length": 200,
                                "null": false,
                                "blank": false,
                                "verbose_name": "Application Name"
                            },
                            {
                                "name": "usage_limit_per_day",
                                "type": "IntegerField",
                                "null": true,
                                "blank": true,
                                "verbose_name": "Usage Limit per Day"
                            },
                            {
                                "name": "usage_limit_per_week",
                                "type": "IntegerField",
                                "null": true,
                                "blank": true,
                                "verbose_name": "Usage Limit per Week"
                            },
                            {
                                "name": "is_blocked",
                                "type": "BooleanField",
                                "default": false,
                                "verbose_name": "Is Blocked"
                            }
                        ]
                    },
                    {
                        "name": "UsageLog",
                        "slug": "usage_log",
                        "fields": [
                            {
                                "name": "child_device",
                                "type": "ForeignKey",
                                "related_name": "device_usage_logs",
                                "to": "ChildDevice"
                            },
                            {
                                "name": "application",
                                "type": "ForeignKey",
                                "related_name": "app_usage_logs",
                                "to": "Application"
                            },
                            {
                                "name": "usage_duration",
                                "type": "IntegerField",
                                "verbose_name": "Usage Duration"
                            },
                            {
                                "name": "timestamp",
                                "type": "DateTimeField",
                                "verbose_name": "Timestamp"
                            }
                        ]
                    }
                ]
            }
        }
    )
    const navigate = useNavigate();

    const [starterChat, setStarterChat] = useState(true)
    const [showLeftSideBar, setShowLeftSideBar] = useState(false)
    const [loginStatus, setLoginStatus] = useState(false)
    const [addItemModal, setAddItemModal] = useState({
        show: false,
        title: '',
        model_name: ''
    })

    useEffect(() => {
        if(!localStorage.getItem('token')){
            navigate('/login');
        }


    }, []);

    const closeModal = (data) => {
        if (data !== undefined) {
            if (data.hasOwnProperty('name')) {
                addNewField(data, addItemModal.model_name)
            }

        }

        setAddItemModal(prevState => ({
            ...prevState,
            show: false
        }));
    }

    const addNewField = (data, model_name) => {

        setConfigJson(prevState => {
            const newModels = [...prevState.data.models]; // Copy the existing models array
            const userModelIndex = newModels.findIndex(model => model.name === model_name); // Find the index of the 'User' model

            // If 'User' model exists, add the new field to its 'fields' array
            if (userModelIndex !== -1) {
                newModels[userModelIndex].fields.push(data);
            }

            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    models: newModels
                }
            };
        });
    };

    return (<ReactFlowProvider>
            <AddFieldModal show={addItemModal.show} closeModal={closeModal} title={addItemModal.title}/>
            <div className='main-container'>
                <div className="main-container2">
                    <Header starterChat={starterChat}/>
                    <div style={{
                        marginTop: '70px',
                        width: '-webkit-fill-available',
                        position: 'relative',
                        height: '-webkit-fill-available',
                        display: 'flex',
                        justifyContent: 'center',
                        // alignItems:'center'
                    }}>
                        <LeftSidebar showLeftSideBar={showLeftSideBar} show={!starterChat}
                        />

                        {!starterChat ?
                            <Flowchart setAddItemModal={setAddItemModal} configJson={configJson}
                                       setShowLeftSideBar={setShowLeftSideBar} starterChat={starterChat}/> :
                            <StartChat starterChat={starterChat} setStarterChat={setStarterChat}/>
                        }
                    </div>
                </div>

                <Chat show={!starterChat}/>
            </div>
        </ReactFlowProvider>
    );
};

export default App