import {Modal} from "react-responsive-modal";
import React, {useState} from "react";
import './../../assets/css/modal.css'
import Switch from "react-switch";

const suppoertedTypesData = {

    'CharField': {
        "field_name": "string", "max_length": "string", "verbose_name": 'string', "null": 'boolean', "blank": 'boolean'
    }, 'EmailField': {
        "field_name": "string", "max_length": "string", "null": 'boolean', "blank": 'boolean', "verbose_name": 'string'
    }, 'IntegerField': {
        "field_name": "string", "max_length": "string", "null": 'boolean', "blank": 'boolean', "verbose_name": 'string'
    }, 'ManyToManyField': {
        "field_name": "string", "max_length": "string", "null": 'boolean', "blank": 'boolean', "verbose_name": 'string'
    }, 'TextField': {
        "field_name": "string", "max_length": "string", "null": 'boolean', "blank": 'boolean', "verbose_name": 'string'
    }, 'DecimalField': {
        "field_name": "string", "max_length": "string", "null": 'boolean', "blank": 'boolean', "verbose_name": 'string'
    }, 'DateField': {
        "field_name": "string", "max_length": "string", "null": 'boolean', "blank": 'boolean', "verbose_name": 'string'
    }, 'ForeignKey': {"field_name": "string"},

}
const AddFieldModal = (props) => {
    const [selectedOption, setSelectedOption] = useState('CharField');
    const [dynamicObject, setDynamicObject] = useState({'type': 'CharField'});

    const addNewValue = (key, value) => {
        // Add a new key-value pair to the dynamicObject
        setDynamicObject(prevState => ({
            ...prevState, [key]: value
        }));
    };

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        addNewValue('type', event.target.value)
    };
    const reset = () => {
        setSelectedOption('CharField')
        setDynamicObject({'type': 'CharField'})
    }

    return (<Modal open={props.show} onClose={() => props.closeModal()} classNames={{
        modal: 'Add-Field_Modal-container',
    }}>
        <div className={"Add-Field_Modal-main-container"}>
            <h2 className={"Add-Field_Modal-main-header"}>Add field to {props.title} Model</h2>

            <div className={"Add-Field_Modal-items-container-main "}>
                <div className={"Add-Field_Modal-items-container "}>
                    <span className={"Add-Field_Modal-items-title"}>
                        field type:
                    </span>
                    <select value={selectedOption} onChange={handleChange} className={"Add-Field_Modal-items-input"}>
                        {Object.entries(suppoertedTypesData).map(([key]) => (
                            <option key={key} value={key}>{key}</option>))}
                    </select>

                </div>
                {Object.entries(suppoertedTypesData[selectedOption]).map(([key, value]) => (
                    <div className={"Add-Field_Modal-items-container "} key={key}>
                                <span className={"Add-Field_Modal-items-title"}>
                            {key}
                        </span>

                        {value === "string" && <input className={"Add-Field_Modal-items-input"}
                                                      value={dynamicObject.hasOwnProperty(key) ? dynamicObject[key] : ''}
                                                      onChange={(event) => addNewValue(key, event.target.value)}/>}
                        {value === "boolean" && <Switch className={"Add-Field_Modal-items-switch"}
                                                        onChange={(sw_value) => addNewValue(key, sw_value)} // Use key here instead of 'switch'
                                                        checked={dynamicObject.hasOwnProperty(key) ? dynamicObject[key] : false}/>}

                    </div>))}

            </div>


            <button className={"Add-Field_Modal-items-submit-button"} onClick={() => {
                props.closeModal(dynamicObject)
                reset()

            }}>
                Okay
            </button>
        </div>
    </Modal>)
}

export default AddFieldModal;