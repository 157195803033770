// App.js
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import App from "./pages/App";
import Login from "./pages/Login";




// App component
const MainRoute = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/app" element={<App/>}/>
                <Route path="/login" element={<Login />}/>
            </Routes>
        </Router>
    );
};

export default MainRoute;