import DocumentImage from './../../assets/images/document-1.svg';
import FolderImage from './../../assets/images/folder-favorite.svg';
import GridImage from './../../assets/images/grid-8.svg';
import ImportImage from './../../assets/images/import-1.svg';
import SearchImage from './../../assets/images/search-normal-1.svg';
import SMSImage from './../../assets/images/sms.svg';
import Arrow from './../../assets/images/arrow-2.svg';

const mini_options = [
    DocumentImage,
    FolderImage,
    GridImage,
    ImportImage,
    SMSImage

]
const main_item = [
    "input / Output Layers",
    "Core Layers",
    "Convolotunal Layers",
    "Pre-Trained Models",
    "Recurrent Layers",
    "Normalization Layers",
]
const LeftSidebar = (props) => {

//
    return (
        <div className="left-sidebar-main-container" style={{display: props.show ? 'flex' : 'none'}}>
            <div className='left-mini-sidebar-container' >
                {mini_options.map((item, index) => {
                    return <img src={item} className='left-mini-sidebar-icon' key={index}/>
                })}
            </div>
            {props.showLeftSideBar &&
                <div className='left-sidebar-container'>
                    <div className='left-sidebar-search-container'>
                        <img src={SearchImage} className='left-sidebar-search-icon'/>
                        <input className='left-sidebar-search-input' placeholder='Search ....'/>
                    </div>
                    <div style={{marginTop: 20}}>
                        {main_item.map((item) => (
                            <div className='left-sidebar-option-container' key={item}>
                                <img src={Arrow} className='left-sidebar-option-icon'/>
                                <span className='left-sidebar-option-text'>{item}</span>
                            </div>
                        ))}
                    </div>


                </div>
            }
        </div>
    )
}
export default LeftSidebar;