import './../../assets/css/chat.css'
import ChatList from "../chat/chat_list";
import MessageBox from "../chat/message_box";
import {useEffect, useState} from "react";
import ChatHeader from "../chat/header";

const StartChat = (props) => {
    const [messages, setMessages] = useState([])
    const [webSocket, setWebSocket] = useState(null);

    useEffect(() => {
        // Create a new WebSocket connection when the component mounts
        const ws = new WebSocket('ws://localhost:8000/ws');

        // Set up event listeners for the WebSocket connection
        ws.onopen = () => {
            console.log('WebSocket connected');
        };

        ws.onmessage = (event) => {
            // When a message is received, add it to the messages array
            setMessages(prevMessages => [...prevMessages, {isBot: false, data: event.data}]);
        };

        ws.onclose = () => {
            console.log('WebSocket disconnected');
        };

        // Save the WebSocket connection in state
        setWebSocket(ws);

        // Clean up function to close the WebSocket connection when the component unmounts
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, []);
    const sendMessage = (message) => {
        setMessages(prevMessages => [...prevMessages, {isBot: true, data: message}]);
        // Check if the WebSocket connection is open before sending a message
        if (webSocket && webSocket.readyState === WebSocket.OPEN) {
            webSocket.send(message);
        } else {
            console.error('WebSocket connection not open');
        }
    };
    return (

        <div className="chat-container-chat">
            <button onClick={(e) => {
                props.setStarterChat(false)
            }}>
                hiihihihi
            </button>

            <ChatList messages={messages}/>
            <MessageBox sendMessage={sendMessage} mainChat={props.starterChat}/>
        </div>

    )
}
export default StartChat;