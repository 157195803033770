import {useEffect, useState} from 'react';
import CodeImage from './../../assets/images/Code.svg';
import MagicPenImage from './../../assets/images/magicpen.svg';
import ZoomInImage from './../../assets/images/search-zoom-in.svg';
import ZoomOutImage from './../../assets/images/search-zoom-out.svg';
import DownloadImage from './../../assets/images/icon _download_.svg';
import UploadImage from './../../assets/images/icon _upload_.svg';
import SearchImage from './../../assets/images/search-normal-1.svg'
import ViewPort from './../../assets/images/viewport.svg'


const Toolbar = (props) => {
    const tools = {
        Code: {icon: CodeImage, action: props.Code},
        ZoomIn: {icon: ZoomInImage, action: props.ZoomIn},
        ZoomOut: {icon: ZoomOutImage, action: props.ZoomOut},
        Import: {icon: UploadImage, action: props.Import},
        Export: {icon: DownloadImage, action: props.Export},
        Magic: {icon: MagicPenImage, action: props.Magic},
        ViewPort: {icon: ViewPort, action: props.ViewPort},
    }
    const stepsObject = {
        "Start": {
            options: [
                tools.Magic,
                tools.ZoomIn,
                tools.ZoomOut,
                tools.Export,
                tools.Import,
                tools.ViewPort,
            ],
            toolbar: false
        },
        "Logic": {
            options: [
                tools.ZoomIn,
                tools.ZoomOut,
                tools.Export,
                tools.Import,
            ],
            toolbar: true
        },
        "Run": {
            options: [
                tools.ZoomIn,
                tools.ZoomOut,
                tools.Export,
                tools.Import,
                tools.Code
            ],
            toolbar: true
        }
    }


    useEffect(() => {
        props.setShowLeftSideBar(stepsObject[props.tab].toolbar)

    }, [props.tab]);

    return (
        <div className='Toolbar-main-container'  style={{display: props.show ? 'block' : 'none'}}>
            <div className='Toolbar-stepbar-container'>
                <div className='Toolbar-stepbar-steps-container'>
                    {
                        Object.entries(stepsObject).map(([key, value], index) => (
                            <span key={key}
                                  className={` ${props.tab === key ? 'Toolbar-stepbar-steps-active' : 'Toolbar-stepbar-steps'}`}
                                  onClick={() => {
                                      props.setTab(key)
                                  }}>
                {key}
            </span>
                        ))
                    }
                </div>
            </div>
            <div className='Toolbar-options-bar-main-container'>
                <div className='Toolbar-options-bar-container'>
                    {
                        stepsObject[props.tab].options.map((item, index) => {
                            return <img src={item.icon} className='Toolbar-options-bar-options-image' onClick={(e)=>item.action()}/>
                        })
                    }
                </div>
                <div className='Toolbar-options-bar-search-container'>
                    <img src={SearchImage} className='Toolbar-options-bar-search-icon'/>
                    <input className='Toolbar-options-bar-search-input' placeholder='Search ....'/>

                </div>

            </div>
        </div>
    )
}
export default Toolbar;