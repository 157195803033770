import Send from './../../assets/images/send.svg';
import Microphone from './../../assets/images/microphone-2.svg';
import React, { useState } from 'react';

const MessageBox = (props) => {
    const [text, setText] = useState('');

    const sendMessage=()=>{
        setText('')
        props.sendMessage(text)
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevents the default behavior of new line insertion
            // Handle your logic here, for example, submit the form or perform an action
            sendMessage()
        }
    };
    return (
        <div className="chat-header-container chat-message-box-container" style={{backgroundColor:props.mainChat && '#131314'}}>
            <div className='chat-message-box-text-container'>
                <img src={Send} className='chat-message-box-text-icon' onClick={(e) =>{sendMessage()}}/>
                <div className='chat-message-box-container-vertical-divider' />
                <img src={Microphone} className='chat-message-box-text-icon' />
                <textarea className='chat-message-box-container-textarea' value={text} placeholder='write your text' onChange={(e) => setText(e.target.value)}
                    onKeyDown={handleKeyPress} />
            </div>
        </div>
    )
}
export default MessageBox;